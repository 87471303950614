// extracted by mini-css-extract-plugin
export var blocksContainer = "payload-module--blocks-container--acf9e";
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--8761f";
export var blocksInRowsWithOneActiveInnerWrapperTwo = "payload-module--blocks-in-rows-with-one-active-inner-wrapper-two--b236d";
export var blocksInRowsWithOneActiveSection = "payload-module--blocks-in-rows-with-one-active-section--71c72";
export var blocksInRowsWithOneActiveSectionTwo = "payload-module--blocks-in-rows-with-one-active-section-two--d7435";
export var blocksRedirectingToResourcesSubtitle = "payload-module--blocks-redirecting-to-resources-subtitle--50e46";
export var blocksRedirectingToResourcesTitlesContainer = "payload-module--blocks-redirecting-to-resources-titles-container--715ac";
export var coloredBlocksContainer = "payload-module--colored-blocks-container--645fa";
export var coloredBlocksInnerWrapper = "payload-module--colored-blocks-inner-wrapper--fd7c5";
export var coloredBlocksRightPart = "payload-module--colored-blocks-right-part--63016";
export var commonOuterWrapper = "payload-module--common-outer-wrapper--685f3";
export var companyInNumbersTitlesContainer = "payload-module--company-in-numbers-titles-container--1c555";
export var contactButtonTextSection = "payload-module--contact-button-text-section--6e879";
export var contactButtonTextSectionInnerWrapper = "payload-module--contact-button-text-section-inner-wrapper--6fcec";
export var customSubtitle = "payload-module--custom-subtitle--5ce88";
export var headerInnerWrapper = "payload-module--header-inner-wrapper--0bdcd";
export var headerTitle = "payload-module--header-title--8b8de";
export var headerTitleBorderWidth = "payload-module--header-title-border-width--d2ef1";
export var imageContainer = "payload-module--image-container--c957c";
export var opacitySliderInnerWrapper = "payload-module--opacity-slider-inner-wrapper--8ea32";
export var opacitySliderTitlesContainer = "payload-module--opacity-slider-titles-container--435ec";
export var opacitySliderVisibleText = "payload-module--opacity-slider-visible-text--ad6bc";
export var sectionUnderHeaderButton = "payload-module--section-under-header-button--17674";
export var sectionUnderHeaderContentWrapper = "payload-module--section-under-header-content-wrapper--ec415";
export var sectionUnderHeaderInnerWrapper = "payload-module--section-under-header-inner-wrapper--6893f";
export var sectionUnderHeaderToggleVisibilityButton = "payload-module--section-under-header-toggle-visibility-button--75c7f";
export var singleBlockContainer = "payload-module--single-block-container--861db";
export var sliderWithClickableBlocksSection = "payload-module--slider-with-clickable-blocks-section--2d3d8";
export var sliderWithClickableBlocksTitlesContainer = "payload-module--slider-with-clickable-blocks-titles-container--dc5bc";
export var techStackContainerWithTwoColumns = "payload-module--tech-stack-container-with-two-columns--0f575";
export var techStackIconsForCategory = "payload-module--tech-stack-icons-for-category--9aeac";
export var techStackInnerWrapper = "payload-module--tech-stack-inner-wrapper--2df63";
export var techStackSingleCategory = "payload-module--tech-stack-single-category--c4289";
export var techStackSingleColumn = "payload-module--tech-stack-single-column--9aec6";
export var techStackSubtitleStyles = "payload-module--tech-stack-subtitle-styles--c5482";
export var titlesContainer = "payload-module--titles-container--7a3c5";